import instagramLogo from "../assets/socialLogos/instagram logo.png";
import tiktokLogo from "../assets/socialLogos/tiktokLogo.png";
import discordLogo from "../assets/socialLogos/discordLogo.png";
import itchLogo from "../assets/socialLogos/itchLogo.webp";
import twitterLogo from "../assets/socialLogos/twitter logo.png";
import youtubeLogo from "../assets/socialLogos/youtube logo.png";
// import redditLogo from "../assets/socialLogos/reddit-logo.png";
import facebookLogo from "../assets/socialLogos/facebook logo.png";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  return (
    <div className="App-footer">
      <div className="m-1"></div>
      <div
        className="d-flex"
        style={{ gap: "1rem", flexWrap: "wrap", justifyContent: "center" }}
      >
        <a
          href="https://instagram.com/starworksstudios?igshid=YmMyMTA2M2Y="
          target="_blank"
        >
          <img src={instagramLogo} className="footerLink" />
        </a>
        <a href="https://www.tiktok.com/@starworksstudios" target="_blank">
          <img src={tiktokLogo} className="footerLink" />
        </a>
        <a href="https://discord.gg/xJA8rPGAuv" target="_blank">
          <img src={discordLogo} className="footerLink" />
        </a>
        <a href="https://starworks-studios.itch.io/" target="_blank">
          <img src={itchLogo} className="footerLink" />
        </a>
        <a href="https://twitter.com/StarwrksStudios" target="_blank">
          <img src={twitterLogo} className="footerLink" />
        </a>
        <a
          href="https://www.youtube.com/channel/UC_4UGFC1-UJYuuDU_WN7YnA"
          target="_blank"
        >
          <img src={youtubeLogo} className="footerLink" />
        </a>
        {/* <a href="" target="_blank">
          <img src={redditLogo} className="footerLink" />
        </a> */}
      </div>
      <div className="m-1"></div>
      <div
        className="d-flex"
        style={{
          gap: "1rem",
          fontSize: "1.5rem",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        <div onClick={() => navigate("/team")} style={{ cursor: "pointer" }}>
          About Us
        </div>
        {/* <div onClick={() => navigate("/privacy")} style={{ cursor: "pointer" }}>
          Privacy Policy
        </div>
        <div onClick={() => navigate("/tos")} style={{ cursor: "pointer" }}>
          Terms of Service
        </div> */}
        <div onClick={() => navigate("/contact")} style={{ cursor: "pointer" }}>
          Contact Us
        </div>
      </div>
      <div className="m-1"></div>
      <div
        className="text-center"
        style={{ color: "GrayText", fontSize: "1rem" }}
      >
        ©{new Date().getFullYear()} Starworks Studios LLC. All rights reserved.
      </div>
      <div className="m-1"></div>
    </div>
  );
};

export default Footer;
